import React from 'react';
import Layout from '../../layout/pl';
import TeamPerson from '../../components/TeamPerson';

import Radek from '../../images/team/radek-small.png';
import cert1 from '../../images/certyfikaty_radek/1.jpg';
import cert2 from '../../images/certyfikaty_radek/2.jpg';
import cert3 from '../../images/certyfikaty_radek/3.jpg';
import cert4 from '../../images/certyfikaty_radek/4.jpg';
import cert5 from '../../images/certyfikaty_radek/5.jpg';
import cert6 from '../../images/certyfikaty_radek/6.jpg';
import cert7 from '../../images/certyfikaty_radek/7.jpg';
import cert8 from '../../images/certyfikaty_radek/8.jpg';
import cert9 from '../../images/certyfikaty_radek/9.jpg';
import cert10 from '../../images/certyfikaty_radek/10.jpg';
import cert11 from '../../images/certyfikaty_radek/11.jpg';
import cert12 from '../../images/certyfikaty_radek/12.jpg';
import cert13 from '../../images/certyfikaty_radek/13.jpg';
import cert14 from '../../images/certyfikaty_radek/14.jpg';
import cert15 from '../../images/certyfikaty_radek/15.jpg';
import cert16 from '../../images/certyfikaty_radek/16.jpg';
import cert17 from '../../images/certyfikaty_radek/17.jpg';
import cert18 from '../../images/certyfikaty_radek/18.jpg';
import cert19 from '../../images/certyfikaty_radek/19.jpg';
import cert20 from '../../images/certyfikaty_radek/20.jpg';
import cert21 from '../../images/certyfikaty_radek/21.jpg';

const certification = [
	cert1,
	cert2,
	cert3,
	cert4,
	cert5,
	cert6,
	cert7,
	cert8,
	cert9,
	cert10,
	cert11,
	cert12,
	cert13,
	cert14,
	cert15,
	cert16,
	cert17,
	cert18,
	cert19,
	cert20,
	cert21
];
const handleClick = () => {
	window.medicall_modal('336fbc3e6b050a0c3be5');
	return false;
};

const RadoslawLysek = (props) => {
	return (
		<Layout {...props}>
			<TeamPerson img={Radek} handleClick={handleClick} certification={certification} person="radoslaw" />
		</Layout>
	);
};
export default RadoslawLysek;
